/*
  <<
  root element for the scrollable.
  when scrolling occurs this element stays still.
  >>
*/

.scrollable
{
  /* required settings */
  position: relative;
  overflow: hidden;
  width: 890px;
  height: 200px;
  border-right: 1px solid #d1d1d1;
  border-left: 1px solid #d1d1d1;
}

/*
 root element for scrollable items. Must be absolutely positioned
 and it should have a extremely large width to accomodate scrollable
 items.  it's enough that you set the width and height for the root
 element and not for this element.
*/

.scrollable .items
{
  /* this cannot be too large */
  width: 20000em;
  position: absolute;
  clear: both;
}

.items div
{
  float: left;
  width: 890px;
}

/* single scrollable item */

.scrollable .s_item
{
  display: block;
  float: left;
  padding: 20px 8px 10px 8px;
  border-right: 1px dashed #ccc;
  width: 160px;
  height: 170px;
  text-decoration: none;
  overflow: hidden;
}

.scrollable .s_item:hover
{
  background-color: #eee;
}

.scrollable .last
{
  border-right: none;
}

.scrollable .s_item img
{
  width: 160px;
  height: 106px;
}

.scrollable .s_item h4
{
  color: #808080;
  line-height: 1.85em;
}

.scrollable .s_item p
{
  color: #666;
  font-size: 0.95em;
  margin-top: 2px;
  line-height: 1.4em;
}

.scrollable .s_item:hover p
{
  color: #0060b9;
}

/* active item */

.scrollable .active
{
  border: 2px solid #000;
  position: relative;
  cursor: default;
}

/* <<  buttons  >>  */

.scrollable
{
  float: left;
}

/* prev, next, prevPage and nextPage buttons */

a.browse
{
  background: url(hori_large.jpg) no-repeat;
  display: block;
  margin-top: 60px;
  width: 33px;
  height: 33px;
  float: left;
  cursor: pointer;
  font-size: 1px;
}

/* right */

a.right
{
  position: absolute;
  right: 0;
  background-position: 0 -33px;
  clear: right;
  margin-left: 9px;
}

a.right:hover
{
  background-position: -33px -33px;
}

a.right:active
{
  background-position: -33px -33px;
}

/* left */

a.left
{
  margin-left: 2px;
}

a.left:hover
{
  background-position: -33px 0;
}

a.left:active
{
  background-position: -33px 0;
}

/* up and down */

a.up, a.down
{
  background: url(vert_large.png) no-repeat;
  float: none;
  margin: 10px 50px;
}

/* up */

a.up:hover
{
  background-position: -33px 0;
}

a.up:active
{
  background-position: -66px 0;
}

/* down */

a.down
{
  background-position: 0 -33px;
}

a.down:hover
{
  background-position: -33px -33px;
}

a.down:active
{
  background-position: -66px -33px;
}

/* disabled navigational button */

a.disabled
{
}

.navi a
{
  width: 10px;
  height: 10px;
  float: left;
  margin: 3px;
  background: url(navigatorBig.jpg) 0 -10px no-repeat;
  display: block;
  font-size: 1px;
}

/* mouseover state */

.navi a:hover
{
  background-position: 0 0;
}

/* active state (current page state) */

.navi a.active
{
	background-position: 0 -20px;
}

.scroll_navi
{
  position: relative;
  z-index: 100;
  margin-top: -20px;
  float: right;
  margin-right: 20px;
}

.ad_box_02 
{
  background: url(default_ad_02_bg.jpg) no-repeat;
  position: relative;
  top: -201px;
  left: 744px;
  width: 200px;
  border-right: 1px solid #d1d1d1;
  height: 200px;
  overflow: hidden;
}
